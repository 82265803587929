import React, { useState, Fragment } from 'react';
import { Form, Collapse, Row, Col, message, Switch, Upload, Select, InputNumber } from 'antd';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { getInsurers, addOpdCovers } from '../../helpers/apiCalls';
import Label from 'reactstrap/lib/Label';
import slugify from 'react-slugify';
import Loader from '../../components/Loader';


const { Panel } = Collapse;
const { Option } = Select;
const layout = {
    labelCol: {
        span: 24,
    },
    wrapperCol: {
        span: 20,
    },
};



const AddOpdCover = (props) => {
    const [form] = Form.useForm();
    const [referesh, setReferesh] = useState(true);
    const [loading, setLoading] = useState(false);
    const [options, setOptions] = useState([]);
    const [insurer, setInsurer] = useState({});


    const onReset = () => {
        form.resetFields();
    };


    const onFinish = (values) => {
        setLoading(true);
        console.log("Form Data : ", values)
        let data = {
            insurer: values.insurer,
            noOfLives: values.noOfLives,
            noOfEmployees: values.noOfEmployees,
            si: values.si,
            ratio: values.ratio,
            opdLimit: values.opdLimit,
            coPay: values.coPay,
            OPDLoading: values.OPDLoading,
        };
        console.log('submit', data)
        addOpdCovers(data)
            .then((res) => {
                console.log('response is ', res)
                setLoading(false)
                message.success('Successfully Created');
                props.history.push('/opdcovers');
            })
            .catch((err) => {
                setLoading(false);
                message.error('Plz try again!')
            });
        onReset();
    };

    const searchInsurer = (val) => {
        getInsurers('', '', '', val, 'Name')
            .then((res) => setOptions(res))
            .catch((err) => console.log(err));
    };

    const selectedOption = (id) => {
        let item = options.filter((item) => item.id === id);
        if (item.length) setInsurer(item);
    };

    console.log(options)

    return (
        <Fragment>
            {loading ? (
                <Loader />
            ) : (
                <Row className="my-3">
                    <Col xxl={16} xl={24}>
                        <Form {...layout} form={form} name="control-hooks" onFinish={onFinish}>
                            <div className="d-flex mb-4 px-3 align-items-center justify-content-between">
                                <h2>Add New OPD Cover</h2>
                                <div>
                                    <button
                                        className="rounded-pill outline-none  text-light"
                                        onClick={(e) => {
                                            props.history.push('/opdcovers');
                                        }}
                                        style={{
                                            background: '#b13e3e',
                                            border: 'none',
                                            padding: '10px 15px',
                                            marginRight: '10px',
                                        }}>
                                        Cancel
                                    </button>
                                    <button
                                        className="rounded-pill outline-none  text-light"
                                        type="submit"
                                        style={{ background: '#a3af60', border: 'none', padding: '10px 15px' }}>
                                        Save & Publish
                                    </button>
                                </div>
                            </div>
                            <div className="card p-4 shadow">
                                <Col span={24}>
                                    <Form.Item name="insurer" label="Insurer" wrapperCol={{ span: 22 }}>
                                        <Select
                                            filterOption={(inputValue, option) =>
                                                option.children
                                                    .toString()
                                                    .toLowerCase()
                                                    .includes(inputValue.toLowerCase())
                                            }
                                            showSearch
                                            onSearch={(val) => {
                                                if (val.length > 1) {
                                                    searchInsurer(val);
                                                }
                                            }}
                                            onSelect={(a, b) => selectedOption(a)}
                                            value={[]}
                                            // onSelect={onSelect}
                                            placeholder="Insurer"
                                            allowClear>
                                            {options.length
                                                ? options.map((item) => (
                                                    <Option key={item.id} name='Insurer' value={item.id}>
                                                        {item.name}
                                                    </Option>
                                                ))
                                                : null}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Row>
                                    <Col span={8} className="my-2">
                                        <Label>No OF Lives</Label>
                                        <Form.Item name={'noOfLives'}>
                                            <InputNumber />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8} className="my-2">
                                        <Label>No Of Employees</Label>
                                        <Form.Item
                                            name="noOfEmployees"
                                            wrapperCol={{ span: 22 }}
                                            placeholder="noOfEmployees"
                                        >
                                            <InputNumber />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8} className="my-2">
                                        <Label>Si</Label>
                                        <Form.Item
                                            name="si"
                                            wrapperCol={{ span: 22 }}
                                            placeholder="SI"
                                        >
                                            <InputNumber />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8} className="my-2">
                                        <Label>Opd Limit</Label>
                                        <Form.Item
                                            name="opdLimit"
                                            wrapperCol={{ span: 22 }}
                                            placeholder="OPD Limit"
                                        >
                                            <InputNumber />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8} className="my-2">
                                        <Label>Co Pay</Label>
                                        <Form.Item
                                            name="coPay"
                                            wrapperCol={{ span: 22 }}
                                            placeholder="Co Pay"
                                        >
                                            <InputNumber />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8} className="my-2">
                                        <Label>Ratio</Label>
                                        <Form.Item
                                            name="ratio"
                                            wrapperCol={{ span: 22 }}
                                            placeholder="Discount"
                                        >
                                            <InputNumber />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8} className="my-2">
                                        <Label>OPD Loading</Label>
                                        <Form.Item
                                            name="OPDLoading"
                                            wrapperCol={{ span: 22 }}
                                            placeholder="opdLoading"
                                        >
                                            <InputNumber />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </div>
                        </Form>
                    </Col>
                </Row>
            )}
        </Fragment>
    );
};

export default AddOpdCover;

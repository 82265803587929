let mode = 'remoteserver'; // 'localserver' 'remoteserver' 'noserver'
let domain = mode === 'localserver' ? 'http://localhost:1337/' : 'https://calcapi.html5.run/';
// domain = 'http://localhost:1337/';



let appConfig = {
    domain: window.location.hostname === 'dev.html5.run' ? 'html5.run' : 'lauk.in',
    // domain: "http://localhost:1337/",
    videoEntry: domain + 'video-entries',
    socketURL: domain,

    // count: domain,
    adverseclaimloadings: domain + "adverse-claim-loadings",
    opdCovers:domain + 'opd-covers',
    agebanddiscounts: domain + 'age-band-discounts',
    babydayones: domain + 'baby-day-ones',
    citydiscounts: domain + 'city-discounts',
    copayments: domain + 'co-payments',
    corporatefloaters: domain + 'corporate-floaters',
    diseasesublimits: domain + 'disease-sub-limits',
    floaterdiscs: domain + 'floater-discs',
    formulas: domain + 'formulas',
    goodclaimdiscounts: domain + 'good-claim-discounts',
    groupdiscs: domain + 'group-discs',
    insurers: domain + 'insurers',
    maternities: domain + 'maternities',
    maternitydiscounts: domain + 'maternity-discounts',
    newinsertions: domain + 'new-insertions',
    parentalcopayments: domain + 'parental-copayments',
    pedloadings: domain + 'ped-loadings',
    prepostnatals: domain + 'pre-post-natals',
    premiums: domain + 'premiums',
    rncclaimintimationnetworks: domain + 'rnc-claim-intimation-networks',
    roomrentcapings: domain + 'room-rent-capings',
    // users: domain + 'users',
    roles: domain +'users-permissions/roles',


    about: domain + 'about-us',
    home: domain + 'homepage',
    rating: domain + 'ratings',
    category: domain + 'categories',
    mediaFiles: domain + 'media-files',
    products: domain + 'products',
    articles: domain + 'articles',
    catagories: domain + 'categories',
    writers: domain + 'writers',
    recipes: domain + 'recipes',
    cookies: domain + 'cookies',
    privacy: domain + 'privacy',
    faq: domain + 'faq',
    terms: domain + 'terms',
    orders: domain + 'orders',
    userss: domain + 'users',
    week: domain + 'weeks',
    meals: domain + 'mealplans',
    mealorder: domain + 'meal-orders',
    deleteTables: domain + 'delete-tables',
    count: domain,
    branches: domain + 'branches',
    vendors: domain + 'vendors',
    orderedProduct: domain + 'ordered-products',
    purchase: domain + 'purchase-orders',
    loginUrl: domain + 'auth/local',
    register: domain + 'auth/local/register',
    users: domain + 'users',
    meetings: domain + 'meetings',
    callLog: domain + 'logs',
    billingLink: domain + 'billings/link',
    formula: domain + 'formulas',
    productionJobs: domain + 'production-jobs',
    raw: domain + 'raws',
    contactUs: domain + 'contact-us',

    upload: domain + 'upload',
    registration: domain + 'registrations',

    getical: domain + 'email/getics',

    functionList: [
        "basicCalc",
        "prem",
        "city",
        "group",
        "floater",
        "maternitydiscount",
        'copayment',
        "parentalcopayment",
        "goodclaimdiscount",
        "maternity",
        "agebanddiscount",
        "roomrentcaping",
        "pedloading",
        "babydayone",
        "prepostnatal",
        "diseasesublimit",
        "newinsertions",
        "rncclaimintimationnetwork",
        "corporatefloater",
        "adverseclaimloading",
        "sumAll",
    ]
    ,
    config: {
        // startAudioOnly: true,
        startWithAudioMuted: true,
        debug: false,
        // disable1On1Mode: true,
        doNotStoreRoom: true,
        remoteVideoMenu: {
            disableKick: true,
        },
        disableRemoteMute: true,

        disableDeepLinking: true,

        ///////
        disableThirdPartyRequests: true,
        // Disable Audio Dots
        disableAudioLevels: true,
        enableNoisyMicDetection: false,

        startWithVideoMuted: true,

        // // Disable H.264
        // // disableH264: true,
        // // p2p: {
        // //     disableH264: true,
        // // },
        // // Disables all audio processing
        disableAP: false,
        // // Disables Acoustic Echo Cancellation
        disableAEC: false,
        // // Disables Automatic Gain Control
        disableAGC: false,
        // // Disables Noise Suppression
        disableNS: false,
        // // Disables Highpass Filter
        disableHPF: true,
        ///////
        enableLayerSuspension: false,

        prejoinPageEnabled: false,
        // Limit number of video feeds forwarded
        // channelLastN: 6,
        fileRecordingsEnabled: true,
        liveStreamingEnabled: true,

        // resolution: 480,
        // constraints: {
        //     video: {
        //         height: {
        //             ideal: 480,
        //             max: 720,
        //             min: 240
        //         }
        //     }
        // },
    },

    interfaceConfig: {
        DISABLE_VIDEO_BACKGROUND: true,
        DEFAULT_BACKGROUND: '#e5e3e3',
        // DEFAULT_BACKGROUND: '#e5e3e3',
        DEFAULT_REMOTE_DISPLAY_NAME: 'Guest',
        TOOLBAR_BUTTONS: [
            // 'microphone',
            // 'camera',
            'desktop',
            'fullscreen',
            'fodeviceselection',
            'hangup',
            // 'profile',
            'chat',
            'settings',
            'raisehand',
            'videoquality',
            'filmstrip',
            'tileview',
            // 'download',
            // 'recording',
            // 'livestreaming',
            // 'stats', 'mute-everyone', 'sharedvideo',
            // 'videobackgroundblur',
            // 'etherpad',
        ],

        // DISABLE_JOIN_LEAVE_NOTIFICATIONS: true,
        DISABLE_FOCUS_INDICATOR: true,
        DISABLE_DOMINANT_SPEAKER_INDICATOR: true,

        //
        // VERTICAL_FILMSTRIP: false,
        // filmStripOnly: true,
        SET_FILMSTRIP_ENABLED: true,
    },

    webinarToolbar: [
        // 'microphone',
        // 'camera',
        // 'desktop',
        'fullscreen',
        // 'fodeviceselection',
        'hangup',
        // 'profile',
        'chat',
        // 'settings',
        'raisehand',
        // 'videoquality',
        // 'filmstrip',
        // 'tileview',

        // 'download',
        // 'recording',
        // 'livestreaming',
        // 'stats', 'mute-everyone', 'sharedvideo',
        // 'videobackgroundblur',
        // 'etherpad',
    ],
};

export default appConfig;

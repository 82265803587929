import React, { useState, useEffect, Fragment } from 'react';
import { Form, Row, Col, message, Switch, Select, Upload, Collapse, InputNumber } from 'antd';
import { getInsurers, getOpdCovers, updateOpdCover } from '../../helpers/apiCalls';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import Label from 'reactstrap/lib/Label';
import Loader from '../../components/Loader';
import FormList from '../../components/FormList/FormList';

const { Panel } = Collapse;
const { Option } = Select;

const layout = {
    labelCol: {
        span: 24,
    },
    wrapperCol: {
        span: 20,
    },
};

const EditOpdCovers = (props) => {
    const [form] = Form.useForm();
    const [options, setOptions] = useState([]);
    const [referesh, setReferesh] = useState(true);
    const [loading, setLoading] = useState(false);
    const [insurer, setInsurer] = useState({});

    const { id } = props.match.params;

    useEffect(() => {
        getOpdCover();
    }, []);

    const onReset = () => {
        form.resetFields();
    };

    const getOpdCover = () => {
        getOpdCovers(id)
            .then((res) => {
                const formValues = form.getFieldValue();
                console.log('formvalues', formValues)
                formValues.insurer = res.insurer.name || '';
                formValues.noOfLives = res.noOfLives;
                formValues.noOfEmployees = res.noOfEmployees;
                formValues.si = res.si;
                formValues.ratio = res.ratio;
                formValues.opdLimit = res.opdLimit;
                formValues.coPay = res.coPay;
                formValues.OPDLoading = res.OPDLoading;


                setInsurer(res.insurer)
                setReferesh(!referesh)
            })
            .catch((err) => console.log(err));
    };

    const searchInsurer = (val) => {
        getInsurers('', '', '', val, 'Name')
            .then((res) => setOptions(res))
            .catch((err) => console.log(err));
    };

    const selectedOption = (id) => {
        let item = options.filter((item) => item.id === id);
        if (item.length) setInsurer(item[0]);
        console.log('item', insurer)
    };

    const onFinish = (values) => {
        setLoading(true);
        let formData = form.getFieldsValue(true);

        let data = {
            ...values,
            insurer: insurer,
            noOfLives: values.noOfLives,
            noOfEmployees: values.noOfEmployees,
            si: values.si,
            ratio: values.ratio,
            opdLimit: values.opdLimit,
            coPay: values.coPay,
            OPDLoading: values.OPDLoading,
        };
        console.log('data', data)
        updateOpdCover(id, data)
            .then((res) => {
                setLoading(false);
                message.success('successfully Updated ');
                props.history.push('/opdcovers');
            })
            .catch((err) => {
                setLoading(false);
                getOpdCover()
                message.error('plz try again')
            });
        onReset();
    };


    return (
        <Fragment>
            {loading ? (
                <Loader />
            ) : (
                <Row className="my-3">
                    <Col xxl={24} xl={24}>
                        <Form
                            onValuesChange={() => setReferesh(!referesh)}
                            {...layout}
                            form={form}
                            name="control-hooks"
                            onFinish={onFinish}>
                            <div className="d-flex mb-4 px-3 align-items-center justify-content-between">
                                <h2>Edit OPD Cover</h2>

                                <div>
                                    <button
                                        className="rounded-pill outline-none  text-light"
                                        onClick={(e) => {
                                            props.history.push('/opdcovers');
                                        }}
                                        style={{
                                            background: '#b13e3e',
                                            border: 'none',
                                            padding: '10px 15px',
                                            marginRight: '10px',
                                        }}>
                                        Cancel
                                    </button>
                                    <button
                                        className="rounded-pill outline-none  text-light"
                                        type="submit"
                                        style={{ background: '#a3af60', border: 'none', padding: '10px 15px' }}>
                                        Save & Publish
                                    </button>
                                </div>
                            </div>
                            <div className="card p-4 shadow">
                                <Col span={16}>
                                    <Form.Item name="insurer" label="Insurer" wrapperCol={{ span: 22 }}>
                                        <Select
                                            filterOption={(inputValue, option) =>
                                                option.children
                                                    .toString()
                                                    .toLowerCase()
                                                    .includes(inputValue.toLowerCase())
                                            }
                                            showSearch
                                            onSearch={(val) => {
                                                if (val.length > 1) {
                                                    searchInsurer(val);
                                                }
                                            }}
                                            onSelect={(a, b) => selectedOption(a)}
                                            value={[]}
                                            // onSelect={onSelect}
                                            placeholder="Insurer"
                                            allowClear>
                                            {options.length
                                                ? options.map((item) => (
                                                    <Option key={item.id} name='Insurer' value={item.id}>
                                                        {item.name}
                                                    </Option>
                                                ))
                                                : null}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Row>
                                    <Col span={8} className="my-2">
                                        <Label>No OF Lives</Label>
                                        <Form.Item name={'noOfLives'}>
                                            <InputNumber />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8} className="my-2">
                                        <Label>No Of Employees</Label>
                                        <Form.Item
                                            name="noOfEmployees"
                                            wrapperCol={{ span: 22 }}
                                            placeholder="noOfEmployees"
                                        >
                                            <InputNumber />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8} className="my-2">
                                        <Label>Si</Label>
                                        <Form.Item
                                            name="si"
                                            wrapperCol={{ span: 22 }}
                                            placeholder="SI"
                                        >
                                            <InputNumber />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8} className="my-2">
                                        <Label>Opd Limit</Label>
                                        <Form.Item
                                            name="opdLimit"
                                            wrapperCol={{ span: 22 }}
                                            placeholder="OPD Limit"
                                        >
                                            <InputNumber />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8} className="my-2">
                                        <Label>Co Pay</Label>
                                        <Form.Item
                                            name="coPay"
                                            wrapperCol={{ span: 22 }}
                                            placeholder="Co Pay"
                                        >
                                            <InputNumber />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8} className="my-2">
                                        <Label>Ratio</Label>
                                        <Form.Item
                                            name="ratio"
                                            wrapperCol={{ span: 22 }}
                                            placeholder="Discount"
                                        >
                                            <InputNumber />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8} className="my-2">
                                        <Label>OPD Loading</Label>
                                        <Form.Item
                                            name="OPDLoading"
                                            wrapperCol={{ span: 22 }}
                                            placeholder="opdLoading"
                                        >
                                            <InputNumber />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </div>
                        </Form>
                    </Col>
                </Row>
            )}
        </Fragment>
    );
};

export default EditOpdCovers;




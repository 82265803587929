import React from 'react';
import { Table, Input, Button, Space } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';

class App extends React.Component {
    state = {
        searchText: '',
        searchedColumn: '',
    };

    getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={(node) => {
                        this.searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}>
                        Search
                    </Button>
                    <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({ closeDropdown: false });
                            this.setState({
                                searchText: selectedKeys[0],
                                searchedColumn: dataIndex,
                            });
                        }}>
                        Filter
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
                setTimeout(() => this.searchInput.select(), 100);
            }
        },
        render: (text) =>
            this.state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        console.log('HANFLE SEARCH');
        this.props.searchCallback({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    handleReset = (clearFilters) => {
        clearFilters();
        this.props.searchCallback({
            searchText: '',
            searchedColumn: '',
        });
        this.setState({ searchText: '' });
    };

    render() {
        const newColumns = this.props.columns.map((item) => ({
            ...item,
            ...this.getColumnSearchProps(`${item.dataIndex}`),
        }));
        console.log(this.props);
        // return <Table columns={newColumns} dataSource={this.props.data} />;
        return (
            <Table
                columns={newColumns}
                dataSource={this.props.data}
                onChange={this.props.handleTableChange}
                pagination={{
                    ...this.props.pagination,
                    showSizeChanger: this.props.pagination.total > 10 ? true : false,
                }}
                loading={this.props.loading}
                sortDirections={['ascend', 'descend']}
                // rowSelection={{
                //     type: 'radio',
                //     onSelect: (record, selected, selectedRows, nativeEvent) => {
                //         console.log('e is ', this.props.url + record.key);
                //         let path = this.props.url + record.key;
                //         // this.props.history.push(path);
                //         window.location.href = path;
                //     },
                // }}
            />
        );
    }
}

export default App;

import React from 'react';
import { Col, Row } from 'antd';
import errPic from '../../assets/images/b1.png';

const NoAccess = () => {
    return (
        <div className='fluid-container'>
            <div style={{ marginTop: '40px' }}>
                {/*<div className="row">*/}
                {/*    <div className="card p-3">*/}
                {/*        <h5>*/}
                {/*            You don’t have access to the module. Please contact system administrator for the permissions.*/}
                {/*        </h5>*/}
                {/*    </div>*/}
                {/*</div>*/}
                <Row className='my-3'>
                    <Col xxl={24} xl={24}>
                        <div className='d-flex mb-4 px-3 align-items-center justify-content-between' style={{ background: '#fde183', borderRadius: '20px' }}>
                            <div>
                                <h5>You don’t have access to the module. </h5>
                                {/*<br/>*/}
                                {/*<br/>*/}
                                <h6 style={{fontWeight:'normal'}}>Please contact system administrator for the permissions.</h6>
                            </div>
                            <div>
                                <img src={errPic} alt='403' />
                            </div>
                        </div>
                        {/*<div>*/}
                        {/*    <Col>*/}
                        {/*        */}
                        {/*    </Col>*/}
                        {/*</div>*/}
                        {/*<h5>*/}
                        {/*    You don’t have access to the module. Please contact system administrator for the permissions.*/}
                        {/*</h5>*/}
                    </Col>
                </Row>
            </div>

        </div>
    );
};

export default NoAccess;

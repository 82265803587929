// @flow
import { Cookies } from 'react-cookie';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { fetchJSON } from '../../helpers/api';
import appConfig from '../../config';

import { LOGIN_USER, LOGOUT_USER, REGISTER_USER, FORGET_PASSWORD, UPDATE_USER } from './constants';

import {
    loginUserSuccess,
    loginUserFailed,
    registerUserSuccess,
    registerUserFailed,
    forgetPasswordSuccess,
    forgetPasswordFailed,
} from './actions';
import ReactGA from 'react-ga';

/**
 * Sets the session
 * @param {*} user
 */
const setSession = (user) => {
    let cookies = new Cookies();
    if (user) {
        cookies.set('user', JSON.stringify(user), { path: '/', domain: appConfig.domain });
        if (user.user.id) {
            ReactGA.set({ userId: user.user.id });
            console.log('set ga id : ', user.user.id);
        }
    }
    if (!user) cookies.remove('user', { path: '/', domain: appConfig.domain });
    // }
    if (user) cookies.set('user', JSON.stringify(user), { path: '/' });
    else cookies.remove('user', { path: '/' });
};

/**
 * Login the user
 * @param {*} payload - username and password
 */
function* login({ payload: { username, password } }) {
    const options = {
        //body: JSON.stringify({ username, password }),
        body:
            appConfig.mode === 'noserver'
                ? JSON.stringify({
                      username,
                      password,
                  })
                : JSON.stringify({ identifier: username, password: password }),
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
    };

    // console.log("body ", options);
    // console.log("body ", options);

    try {
        const response = yield call(fetchJSON, appConfig.loginUrl, options);
        // console.log("Login Response is ", response);
        if (response.statusCode) {
            let message = 'Invalid credentials';
            // console.log("resp code", response.statusCode);
            yield put(loginUserFailed(message));
            setSession(null);
        } else {
            // console.log("user is : ", response.user)
            if (response.user.role) delete response.user.role;
            if (response.user.updated_by) delete response.user.updated_by;
            if (response.user.provider) delete response.user.provider;
            if (response.user.subscriptionInit) delete response.user.subscriptionInit;
            if (response.user.priceID) delete response.user.priceID;
            if (response.user.image && response.user.image.formats) delete response.user.image.formats;
            if (response.user.image && response.user.image.alternativeText) delete response.user.image.alternativeText;
            if (response.user.image && response.user.image.caption) delete response.user.image.caption;
            if (response.user.image && response.user.image.createdAt) delete response.user.image.createdAt;
            if (response.user.image && response.user.image.hash) delete response.user.image.hash;
            if (response.user.image && response.user.image.mime) delete response.user.image.mime;
            if (response.user.image && response.user.image.provider) delete response.user.image.provider;
            if (response.user.image && response.user.image.related) delete response.user.image.related;
            // delete response.user.stripeId;
            // delete response.user.subscriptionID;
            // console.log("Pre SetSession  ", response);
            setSession(response);
            yield put(loginUserSuccess(response));
        }
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(loginUserFailed(message));
        setSession(null);
    }
}

/**
 * Logout the user
 * @param {*} param0
 */
function* logout({ payload: { history } }) {
    try {
        setSession(null);
        yield call(() => {
            history.push('/account/login');
        });
    } catch (error) {}
}

/**
 * Register the user
 */
function* register({ payload: { fullname, email, password } }) {
    const options = {
        body: JSON.stringify({ fullname, email, password }),
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
    };

    try {
        const response = yield call(fetchJSON, '/users/register', options);
        yield put(registerUserSuccess(response));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(registerUserFailed(message));
    }
}

/**
 * forget password
 */
function* forgetPassword({ payload: { username } }) {
    const options = {
        body: JSON.stringify({ username }),
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
    };

    try {
        const response = yield call(fetchJSON, '/users/password-reset', options);
        yield put(forgetPasswordSuccess(response.message));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(forgetPasswordFailed(message));
    }
}

// function* userUpdate({payload: {user}}) {
function* userUpdate(usr) {
    // console.log("Saga : ", usr);
    setSession(usr.payload);
    yield put(loginUserSuccess(usr.payload));
}

export function* watchUpdateUser() {
    yield takeEvery(UPDATE_USER, userUpdate);
}

export function* watchLoginUser() {
    yield takeEvery(LOGIN_USER, login);
}

export function* watchLogoutUser() {
    yield takeEvery(LOGOUT_USER, logout);
}

export function* watchRegisterUser() {
    yield takeEvery(REGISTER_USER, register);
}

export function* watchForgetPassword() {
    yield takeEvery(FORGET_PASSWORD, forgetPassword);
}

function* authSaga() {
    yield all([
        fork(watchLoginUser),
        fork(watchLogoutUser),
        fork(watchRegisterUser),
        fork(watchForgetPassword),
        fork(watchUpdateUser),
    ]);
}

export default authSaga;

// @flow
import appConfig from '../config';
import axios from 'axios';
import { getLoggedInUser } from './authUtils';

import * as rdd from 'react-device-detect';

window.rdd = rdd;
// const delay = async (ms) => new Promise((res) => setTimeout(res, ms));

window.onerror = function (error, url, line) {
    // controller.sendLog({acc:'error', data:'ERR:'+error+' URL:'+url+' L:'+line});
    console.log('Error received : ', { acc: 'error', data: 'ERR:' + error + ' URL:' + url + ' L:' + line });
};

const makeHeader = (jwt) => {
    let ujwt = jwt;
    if (!jwt) {
        let user = getLoggedInUser();
        ujwt = user.jwt;
    }
    return {
        headers: {
            Authorization: 'Bearer ' + ujwt,
        },
    };
};

const copyInvite = (userName, roomName, cid, type, password) => {
    let msg = `
${userName} is inviting you to join Lauk ${type}.
${type} Name: ${roomName}
${type} ID: ${cid}
${type} Link
${appConfig.webinarroomLink + cid}
`;

    if (password) {
        msg += `
Room Password: ${password}`;
    }

    return msg;
};


// Getting counts
const getCount = async (type, search = '', filter = '') => {
    let url = appConfig.count + `${type}/count`;

    // if (search && filter) {
    //     url += `?${filter}_contains=${search}`;
    // }
    try {
        return await axios.get(url).then((response) => {
            // console.log('New Meeting', response.data);
            return response.data;
        });
    } catch (error) {
        throw new Error(error);
    }
};

// getting roles for Users ]
const getRoles = async (type, search = '', filter = '') => {
    let url = appConfig.roles

    // if (search && filter) {
    //     url += `?${filter}_contains=${search}`;
    // }
    try {
        return await axios.get(url).then((response) => {
            // console.log('New Meeting', response.data);
            return response.data;
        });
    } catch (error) {
        throw new Error(error);
    }
};

// Getting adverseClaimLoadings

const getAdverseClaimLoadings = async (id = '', limit = '', start = '', search = '', filter = '') => {
    let url = appConfig.adverseclaimloadings;
    if (id) {
        url += `/${id}`;
    } else {
        if (limit) {
            url += `?_start=${start}&_limit=${limit}`;
        } else if (search) {
            url += `?${filter}_contains=${search}`;
        } else url += `?_limit=-1`;
    }
    try {
        return await axios.get(url).then((response) => {
            // console.log('New Meeting', response.data);
            return response.data;
        });
    } catch (error) {
        throw new Error(error);
    }
};

// Getting age band discounts 
const getAgeBandDiscounts = async (id = '', limit = '', start = '', search = '', filter = '') => {
    let url = appConfig.agebanddiscounts;
    if (id) {
        url += `/${id}`;
    } else {
        if (limit) {
            url += `?_start=${start}&_limit=${limit}`;
        } else if (search) {
            url += `?${filter}_contains=${search}`;
        } else url += `?_limit=-1`;
    }
    try {
        return await axios.get(url).then((response) => {
            // console.log('New Meeting', response.data);
            return response.data;
        });
    } catch (error) {
        throw new Error(error);
    }
};

// getting babydayones
const getBabyDayOnes = async (id = '', limit = '', start = '', search = '', filter = '') => {
    let url = appConfig.babydayones;
    if (id) {
        url += '/' + id;
    }

    if (search && filter) {
        url += `?${filter}_contains=${search}`;
        if (limit) {
            url += `&_start=${start}&_limit=${limit}`;
        }
    } else if (limit) {
        url += `?_start=${start}&_limit=${limit}`;
    }

    try {
        return await axios.get(url).then((response) => {
            // console.log('New Meeting', response.data);
            return response.data;
        });
    } catch (error) {
        throw new Error(error);
    }
};

const getOpdCovers = async (id = '', limit = '', start = '', search = '', filter = '') => {
    let url = appConfig.opdCovers;
    if (id) {
        url += '/' + id;
    }

    if (search && filter) {
        url += `?${filter}_contains=${search}`;
        if (limit) {
            url += `&_start=${start}&_limit=${limit}`;
        }
    } else if (limit) {
        url += `?_start=${start}&_limit=${limit}`;
    }

    try {
        return await axios.get(url).then((response) => {
            // console.log('New Meeting', response.data);
            return response.data;
        });
    } catch (error) {
        throw new Error(error);
    }
};

// getting citydiscounts
const getCityDiscounts = async (id = '', limit = '', start = '', search = '', filter = '') => {
    let url = appConfig.citydiscounts;
    if (id) {
        url += `/${id}`;
    } else {
        if (limit) {
            url += `?_start=${start}&_limit=${limit}`;
        } else if (search) {
            url += `?${filter}_contains=${search}`;
        } else url += `?_limit=-1`;
    }
    try {
        return await axios.get(url).then((response) => {
            // console.log('New Meeting', response.data);
            return response.data;
        });
    } catch (error) {
        throw new Error(error);
    }
};


// getting Copayments
const getCoPayments = async (id = '', limit = '', start = '', search = '', filter = '') => {
    let url = appConfig.copayments;
    if (id) {
        url += `/${id}`;
    } else {
        if (limit) {
            url += `?_start=${start}&_limit=${limit}`;
        } else if (search) {
            url += `?${filter}_contains=${search}`;
        } else url += `?_limit=-1`;
    }
    try {
        return await axios.get(url).then((response) => {
            // console.log('New Meeting', response.data);
            return response.data;
        });
    } catch (error) {
        throw new Error(error);
    }
};

// getting corporateFloaters
const getCorporateFloaters = async (id = '', limit = '', start = '', search = '', filter = '') => {
    let url = appConfig.corporatefloaters;
    if (id) {
        url += `/${id}`;
    } else {
        if (limit) {
            url += `?_start=${start}&_limit=${limit}`;
        } else if (search) {
            url += `?${filter}_contains=${search}`;
        } else url += `?_limit=-1`;
    }
    try {
        return await axios.get(url).then((response) => {
            // console.log('New Meeting', response.data);
            return response.data;
        });
    } catch (error) {
        throw new Error(error);
    }
};
// getting diseasesublimit
const getDiseaseSubLimits = async (id = '', limit = '', start = '', search = '', filter = '') => {
    let url = appConfig.diseasesublimits;
    if (id) {
        url += `/${id}`;
    } else {
        if (limit) {
            url += `?_start=${start}&_limit=${limit}`;
        } else if (search) {
            url += `?${filter}_contains=${search}`;
        } else url += `?_limit=-1`;
    }
    try {
        return await axios.get(url).then((response) => {
            // console.log('New Meeting', response.data);
            return response.data;
        });
    } catch (error) {
        throw new Error(error);
    }
};

// getting floater discs
const getFloaterDiscs = async (id = '', limit = '', start = '', search = '', filter = '') => {
    let url = appConfig.floaterdiscs;
    if (id) {
        url += `/${id}`;
    } else {
        if (limit) {
            url += `?_start=${start}&_limit=${limit}`;
        } else if (search) {
            url += `?${filter}_contains=${search}`;
        } else url += `?_limit=-1`;
    }
    try {
        return await axios.get(url).then((response) => {
            // console.log('New Meeting', response.data);
            return response.data;
        });
    } catch (error) {
        throw new Error(error);
    }
};

// getting formulas
const getFormulas = async (id = '', limit = '', start = '', search = '', filter = '') => {
    let url = appConfig.formulas;
    if (id) {
        url += `/${id}`;
    } else {
        if (limit) {
            url += `?_start=${start}&_limit=${limit}`;
        } else if (search) {
            url += `?${filter}_contains=${search}`;
        } else url += `?_limit=-1`;
    }
    try {
        return await axios.get(url).then((response) => {
            // console.log('New Meeting', response.data);
            return response.data;
        });
    } catch (error) {
        throw new Error(error);
    }
};

// getting good claim discounts
const getGoodClaimDiscounts = async (id = '', limit = '', start = '', search = '', filter = '') => {
    let url = appConfig.goodclaimdiscounts;
    if (id) {
        url += `/${id}`;
    } else {
        if (limit) {
            url += `?_start=${start}&_limit=${limit}`;
        } else if (search) {
            url += `?${filter}_contains=${search}`;
        } else url += `?_limit=-1`;
    }

    try {
        return await axios.get(url).then((response) => {
            // console.log('New Meeting', response.data);
            return response.data;
        });
    } catch (error) {
        throw new Error(error);
    }
};

// getting Group discs
const getGroupDiscs = async (id = '', limit = '', start = '', search = '', filter = '') => {
    let url = appConfig.groupdiscs;
    if (id) {
        url += `/${id}`;
    } else {
        if (limit) {
            url += `?_start=${start}&_limit=${limit}`;
        } else if (search) {
            url += `?${filter}_contains=${search}`;
        } else url += `?_limit=-1`;
    }
    try {
        return await axios.get(url).then((response) => {
            // console.log('New Meeting', response.data);
            return response.data;
        });
    } catch (error) {
        throw new Error(error);
    }
};

// getting insurers
const getInsurers = async (id = '', limit = '', start = '', search = '', filter = '') => {
    let url = appConfig.insurers;
    if (id) {
        url += `/${id}`;
    }
    try {
        return await axios.get(url).then((response) => {
            // console.log('New Meeting', response.data);
            return response.data;
        });
    } catch (error) {
        throw new Error(error);
    }
};
// getting maternities
const getMaternities = async (id = '', limit = '', start = '', search = '', filter = '') => {
    let url = appConfig.maternities;
    if (id) {
        url += `/${id}`;
    } else {
        if (limit) {
            url += `?_start=${start}&_limit=${limit}`;
        } else if (search) {
            url += `?${filter}_contains=${search}`;
        } else url += `?_limit=-1`;
    }
    try {
        return await axios.get(url).then((response) => {
            // console.log('New Meeting', response.data);
            return response.data;
        });
    } catch (error) {
        throw new Error(error);
    }
};

// getting maternity discounts
const getMaternityDiscounts = async (id = '', limit = '', start = '', search = '', filter = '') => {
    let url = appConfig.maternitydiscounts;
    if (id) {
        url += `/${id}`;
    } else {
        if (limit) {
            url += `?_start=${start}&_limit=${limit}`;
        } else if (search) {
            url += `?${filter}_contains=${search}`;
        } else url += `?_limit=-1`;
    }
    try {
        return await axios.get(url).then((response) => {
            // console.log('New Meeting', response.data);
            return response.data;
        });
    } catch (error) {
        throw new Error(error);
    }
};

// getting new inseriuonts
const getNewInsertions = async (id = '', limit = '', start = '', search = '', filter = '') => {
    let url = appConfig.newinsertions;
    if (id) {
        url += `/${id}`;
    } else {
        if (limit) {
            url += `?_start=${start}&_limit=${limit}`;
        } else if (search) {
            url += `?${filter}_contains=${search}`;
        } else url += `?_limit=-1`;
    }
    try {
        return await axios.get(url).then((response) => {
            // console.log('New Meeting', response.data);
            return response.data;
        });
    } catch (error) {
        throw new Error(error);
    }
};

// getting paretntal copayments
const getParentalCoPayments = async (id = '', limit = '', start = '', search = '', filter = '') => {
    let url = appConfig.parentalcopayments;
    if (id) {
        url += `/${id}`;
    } else {
        if (limit) {
            url += `?_start=${start}&_limit=${limit}`;
        } else if (search) {
            url += `?${filter}_contains=${search}`;
        } else url += `?_limit=-1`;
    }
    try {
        return await axios.get(url).then((response) => {
            // console.log('New Meeting', response.data);
            return response.data;
        });
    } catch (error) {
        throw new Error(error);
    }
};

// getting pedLoading
const getPedLoadings = async (id = '', limit = '', start = '', search = '', filter = '') => {
    let url = appConfig.pedloadings;
    if (id) {
        url += `/${id}`;
    } else {
        if (limit) {
            url += `?_start=${start}&_limit=${limit}`;
        } else if (search) {
            url += `?${filter}_contains=${search}`;
        } else url += `?_limit=-1`;
    }
    try {
        return await axios.get(url).then((response) => {
            // console.log('New Meeting', response.data);
            return response.data;
        });
    } catch (error) {
        throw new Error(error);
    }
};

// getting permuims
const getPremiums = async (id = '', limit = '', start = '', search = '', filter = '') => {
    let url = appConfig.premiums;
    if (id) {
        url += `/${id}`;
    } else {
        if (limit) {
            url += `?_start=${start}&_limit=${limit}`;
        } else if (search) {
            url += `?${filter}_contains=${search}`;
        } else url += `?_limit=-1`;
    }
    try {
        return await axios.get(url).then((response) => {
            // console.log('New Meeting', response.data);
            return response.data;
        });
    } catch (error) {
        throw new Error(error);
    }
};

// getting prepostnatals
const getPrePostNatals = async (id = '', limit = '', start = '', search = '', filter = '') => {
    let url = appConfig.prepostnatals;
    if (id) {
        url += `/${id}`;
    } else {
        if (limit) {
            url += `?_start=${start}&_limit=${limit}`;
        } else if (search) {
            url += `?${filter}_contains=${search}`;
        } else url += `?_limit=-1`;
    }
    try {
        return await axios.get(url).then((response) => {
            // console.log('New Meeting', response.data);
            return response.data;
        });
    } catch (error) {
        throw new Error(error);
    }
};



// getting Rncclaimintimationnetworks
const getRncClaimIntimationNetworks = async (id = '', limit = '', start = '', search = '', filter = '') => {
    let url = appConfig.rncclaimintimationnetworks;
    if (id) {
        url += `/${id}`;
    } else {
        if (limit) {
            url += `?_start=${start}&_limit=${limit}`;
        } else if (search) {
            url += `?${filter}_contains=${search}`;
        } else url += `?_limit=-1`;
    }
    try {
        return await axios.get(url).then((response) => {
            // console.log('New Meeting', response.data);
            return response.data;
        });
    } catch (error) {
        throw new Error(error);
    }
};

// getting RoomRentCapings
const getRoomRentCapings = async (id = '', limit = '', start = '', search = '', filter = '') => {
    let url = appConfig.roomrentcapings;
    if (id) {
        url += `/${id}`;
    } else {
        if (limit) {
            url += `?_start=${start}&_limit=${limit}`;
        } else if (search) {
            url += `?${filter}_contains=${search}`;
        } else url += `?_limit=-1`;
    }
    try {
        return await axios.get(url).then((response) => {
            // console.log('New Meeting', response.data);
            return response.data;
        });
    } catch (error) {
        throw new Error(error);
    }
};
// getting users 
const getUsers = async (id) => {
    const header = makeHeader();
    try {
        return await axios
            .get(appConfig.userss + '/' + id, header)
            .then((response) => {
                // console.log("MEETINGS API RECEIVED : ", response);
                return response.data;
            })
            .catch((error) => {
                // console.log(error);
                return error;
            });
    } catch (error) {
        console.log(error);
    }
};


// Add APIS 
const addAdverseClaimLoadings = async (data) => {
    const header = makeHeader();
    try {
        return await axios.post(appConfig.adverseclaimloadings, data, header).then((response) => {
            return response.data;
        });
    } catch (error) {
        throw new Error(error);
    }
};


const addOpdCovers = async (data) => {
    const header = makeHeader();
    try {
        return await axios.post(appConfig.opdCovers, data, header).then((response) => {
            return response.data;
        });
    } catch (error) {
        throw new Error(error);
    }
};

const addAgeBandDicounts = async (data) => {
    const header = makeHeader();
    try {
        return await axios.post(appConfig.agebanddiscounts, data, header).then((response) => {
            return response.data;
        });
    } catch (error) {
        throw new Error(error);
    }
};


const addBabyDayOnes = async (data) => {
    const header = makeHeader();
    try {
        return await axios.post(appConfig.babydayones, data, header).then((response) => {
            return response.data;
        });
    } catch (error) {
        throw new Error(error);
    }
};


const addCityDiscounts = async (data) => {
    const header = makeHeader();
    try {
        return await axios.post(appConfig.citydiscounts, data, header).then((response) => {
            return response.data;
        });
    } catch (error) {
        throw new Error(error);
    }
};


const addCoPayments = async (data) => {
    const header = makeHeader();
    try {
        return await axios.post(appConfig.copayments, data, header).then((response) => {
            return response.data;
        });
    } catch (error) {
        throw new Error(error);
    }
};


const addCorporateFloaters = async (data) => {
    const header = makeHeader();
    try {
        return await axios.post(appConfig.corporatefloaters, data, header).then((response) => {
            return response.data;
        });
    } catch (error) {
        throw new Error(error);
    }
};


const addDiseaseSubLimits = async (data) => {
    const header = makeHeader();
    try {
        return await axios.post(appConfig.diseasesublimits, data, header).then((response) => {
            return response.data;
        });
    } catch (error) {
        throw new Error(error);
    }
};


const addFloaterDiscs = async (data) => {
    const header = makeHeader();
    try {
        return await axios.post(appConfig.floaterdiscs, data, header).then((response) => {
            return response.data;
        });
    } catch (error) {
        throw new Error(error);
    }
};

const addFormulas = async (data) => {
    const header = makeHeader();
    try {
        return await axios.post(appConfig.formulas, data, header).then((response) => {
            return response.data;
        });
    } catch (error) {
        throw new Error(error);
    }
};


const addGoodClaimDiscounts = async (data) => {
    const header = makeHeader();
    try {
        return await axios.post(appConfig.goodclaimdiscounts, data, header).then((response) => {
            return response.data;
        });
    } catch (error) {
        throw new Error(error);
    }
};


const addGroupDiscs = async (data) => {
    const header = makeHeader();
    try {
        return await axios.post(appConfig.groupdiscs, data, header).then((response) => {
            return response.data;
        });
    } catch (error) {
        throw new Error(error);
    }
};


const addInsurers = async (data) => {
    const header = makeHeader();
    try {
        return await axios.post(appConfig.insurers, data, header).then((response) => {
            return response.data;
        });
    } catch (error) {
        throw new Error(error);
    }
};


const addMaternities = async (data) => {
    const header = makeHeader();
    try {
        return await axios.post(appConfig.maternities, data, header).then((response) => {
            return response.data;
        });
    } catch (error) {
        throw new Error(error);
    }
};

const addMaternityDiscounts = async (data) => {
    const header = makeHeader();
    try {
        return await axios.post(appConfig.maternitydiscounts, data, header).then((response) => {
            return response.data;
        });
    } catch (error) {
        throw new Error(error);
    }
};


const addNewInsertions = async (data) => {
    const header = makeHeader();
    try {
        return await axios.post(appConfig.newinsertions, data, header).then((response) => {
            return response.data;
        });
    } catch (error) {
        throw new Error(error);
    }
};



const addParentalCopayments = async (data) => {
    const header = makeHeader();
    try {
        return await axios.post(appConfig.parentalcopayments, data, header).then((response) => {
            return response.data;
        });
    } catch (error) {
        throw new Error(error);
    }
};


const addPedLoadings = async (data) => {
    const header = makeHeader();
    try {
        return await axios.post(appConfig.pedloadings, data, header).then((response) => {
            return response.data;
        });
    } catch (error) {
        throw new Error(error);
    }
};


const addPrePostNatals = async (data) => {
    const header = makeHeader();
    try {
        return await axios.post(appConfig.prepostnatals, data, header).then((response) => {
            return response.data;
        });
    } catch (error) {
        throw new Error(error);
    }
};


const addPremiums = async (data) => {
    const header = makeHeader();
    try {
        return await axios.post(appConfig.premiums, data, header).then((response) => {
            return response.data;
        });
    } catch (error) {
        throw new Error(error);
    }
};


const addRncClaimIntimationNetworks = async (data) => {
    const header = makeHeader();
    try {
        return await axios.post(appConfig.rncclaimintimationnetworks, data, header).then((response) => {
            return response.data;
        });
    } catch (error) {
        throw new Error(error);
    }
};



const addRoomRentCapings = async (data) => {
    const header = makeHeader();
    try {
        return await axios.post(appConfig.roomrentcapings, data, header).then((response) => {
            return response.data;
        });
    } catch (error) {
        throw new Error(error);
    }
};
const addUser = async (data) => {
    const header = makeHeader();
    try {
        return await axios.post(appConfig.userss, data).then((response) => {
            // console.log('New Meeting', response.data);
            return response.data;
        });
    } catch (error) {
        throw new Error(error);
    }
};


// Api call for deleting item 

const deleteItem = async (model, id) => {
    const header = makeHeader();
    let url = appConfig[model];
    url += '/' + id;
    console.log(url);
    try {
        return await axios.delete(url, header).then((response) => {
            return response.data;
        });
    } catch (error) {
        throw new Error(error);
    }
};


//Update API's
const updateAdverseClaimLoading = async (ID, data) => {
    const header = makeHeader();
    // const data = {password: pass};
    try {
        return await axios.put(appConfig.adverseclaimloadings + '/' + ID, data, header).then((response) => {
            return response.data;
        });
    } catch (error) {
        throw new Error(error);
    }
};

const updateAgeBandDiscount = async (ID, data) => {
    const header = makeHeader();
    // const data = {password: pass};
    try {
        return await axios.put(appConfig.agebanddiscounts + '/' + ID, data, header).then((response) => {
            // console.log('User Updated : ', response.data);
            return response.data;
        });
    } catch (error) {
        throw new Error(error);
    }
};


const updateBabyDayOne = async (ID, data) => {
    const header = makeHeader();
    // const data = {password: pass};
    try {
        return await axios.put(appConfig.babydayones + '/' + ID, data, header).then((response) => {
            // console.log('User Updated : ', response.data);
            return response.data;
        });
    } catch (error) {
        throw new Error(error);
    }
};
const updateOpdCover = async (ID, data) => {
    const header = makeHeader();
    // const data = {password: pass};
    try {
        return await axios.put(appConfig.opdCovers + '/' + ID, data, header).then((response) => {
            // console.log('User Updated : ', response.data);
            return response.data;
        });
    } catch (error) {
        throw new Error(error);
    }
};

const updateCityDiscount = async (ID, data) => {
    const header = makeHeader();
    // const data = {password: pass};
    try {
        return await axios.put(appConfig.citydiscounts + '/' + ID, data, header).then((response) => {
            // console.log('User Updated : ', response.data);
            return response.data;
        });
    } catch (error) {
        throw new Error(error);
    }
};

const updateCoPayment = async (ID, data) => {
    const header = makeHeader();
    // const data = {password: pass};
    try {
        return await axios.put(appConfig.copayments + '/' + ID, data, header).then((response) => {
            // console.log('User Updated : ', response.data);
            return response.data;
        });
    } catch (error) {
        throw new Error(error);
    }
};

const updateCorporateFloater = async (ID, data) => {
    const header = makeHeader();
    // const data = {password: pass};
    try {
        return await axios.put(appConfig.corporatefloaters + '/' + ID, data, header).then((response) => {
            // console.log('User Updated : ', response.data);
            return response.data;
        });
    } catch (error) {
        throw new Error(error);
    }
};

const updateDiseaseSubLimit = async (ID, data) => {
    const header = makeHeader();
    // const data = {password: pass};
    try {
        return await axios.put(appConfig.diseasesublimits + '/' + ID, data, header).then((response) => {
            // console.log('User Updated : ', response.data);
            return response.data;
        });
    } catch (error) {
        throw new Error(error);
    }
};

const updateFloaterDisc = async (ID, data) => {
    const header = makeHeader();
    // const data = {password: pass};
    try {
        return await axios.put(appConfig.floaterdiscs + '/' + ID, data, header).then((response) => {
            // console.log('User Updated : ', response.data);
            return response.data;
        });
    } catch (error) {
        throw new Error(error);
    }
};

const updateFormula = async (ID, data) => {
    const header = makeHeader();
    // const data = {password: pass};
    try {
        return await axios.put(appConfig.formulas + '/' + ID, data, header).then((response) => {
            // console.log('User Updated : ', response.data);
            return response.data;
        });
    } catch (error) {
        throw new Error(error);
    }
};

const updateGoodClaimDiscount = async (ID, data) => {
    const header = makeHeader();
    // const data = {password: pass};
    try {
        return await axios.put(appConfig.goodclaimdiscounts + '/' + ID, data, header).then((response) => {
            // console.log('User Updated : ', response.data);
            return response.data;
        });
    } catch (error) {
        throw new Error(error);
    }
};
const updateGroupDisc = async (ID, data) => {
    const header = makeHeader();
    // const data = {password: pass};
    try {
        return await axios.put(appConfig.groupdiscs + '/' + ID, data, header).then((response) => {
            // console.log('User Updated : ', response.data);
            return response.data;
        });
    } catch (error) {
        throw new Error(error);
    }
};

const updateInsurer = async (ID, data) => {
    const header = makeHeader();
    // const data = {password: pass};
    try {
        return await axios.put(appConfig.insurers + '/' + ID, data, header).then((response) => {
            // console.log('User Updated : ', response.data);
            return response.data;
        });
    } catch (error) {
        throw new Error(error);
    }
};

const updateMaternitie = async (ID, data) => {
    const header = makeHeader();
    // const data = {password: pass};
    try {
        return await axios.put(appConfig.maternities + '/' + ID, data, header).then((response) => {
            // console.log('User Updated : ', response.data);
            return response.data;
        });
    } catch (error) {
        throw new Error(error);
    }
};

const updateMaternityDiscount = async (ID, data) => {
    const header = makeHeader();
    // const data = {password: pass};
    try {
        return await axios.put(appConfig.maternitydiscounts + '/' + ID, data, header).then((response) => {
            // console.log('User Updated : ', response.data);
            return response.data;
        });
    } catch (error) {
        throw new Error(error);
    }
};

const updateNewInsertion = async (ID, data) => {
    const header = makeHeader();
    // const data = {password: pass};
    try {
        return await axios.put(appConfig.newinsertions + '/' + ID, data, header).then((response) => {
            // console.log('User Updated : ', response.data);
            return response.data;
        });
    } catch (error) {
        throw new Error(error);
    }
};

const updateParentalCopayment = async (ID, data) => {
    const header = makeHeader();
    // const data = {password: pass};
    try {
        return await axios.put(appConfig.parentalcopayments + '/' + ID, data, header).then((response) => {
            // console.log('User Updated : ', response.data);
            return response.data;
        });
    } catch (error) {
        throw new Error(error);
    }
};

const updatePedLoading = async (ID, data) => {
    const header = makeHeader();
    // const data = {password: pass};
    try {
        return await axios.put(appConfig.pedloadings + '/' + ID, data, header).then((response) => {
            // console.log('User Updated : ', response.data);
            return response.data;
        });
    } catch (error) {
        throw new Error(error);
    }
};

const updatePrePostNatal = async (ID, data) => {
    const header = makeHeader();
    // const data = {password: pass};
    try {
        return await axios.put(appConfig.prepostnatals + '/' + ID, data, header).then((response) => {
            // console.log('User Updated : ', response.data);
            return response.data;
        });
    } catch (error) {
        throw new Error(error);
    }
};

const updatePremium = async (ID, data) => {
    const header = makeHeader();
    // const data = {password: pass};
    try {
        return await axios.put(appConfig.premiums + '/' + ID, data, header).then((response) => {
            // console.log('User Updated : ', response.data);
            return response.data;
        });
    } catch (error) {
        throw new Error(error);
    }
};

const updateRncClaimIntimationNetwork = async (ID, data) => {
    const header = makeHeader();
    // const data = {password: pass};
    try {
        return await axios.put(appConfig.rncclaimintimationnetworks + '/' + ID, data, header).then((response) => {
            // console.log('User Updated : ', response.data);
            return response.data;
        });
    } catch (error) {
        throw new Error(error);
    }
};

const updateRoomRentCaping = async (ID, data) => {
    const header = makeHeader();
    // const data = {password: pass};
    try {
        return await axios.put(appConfig.roomrentcapings + '/' + ID, data, header).then((response) => {
            // console.log('User Updated : ', response.data);
            return response.data;
        });
    } catch (error) {
        throw new Error(error);
    }
};
const updateUser = async (ID, data) => {
    const header = makeHeader();
    // const data = {password: pass};
    try {
        return await axios.put(appConfig.users + '/' + ID, data, header).then((response) => {
            return response.data;
        });
    } catch (error) {
        throw new Error(error);
    }
};


export {
    getCount,
    getRoles,
    // //////////
    getAdverseClaimLoadings,
    getAgeBandDiscounts,
    getBabyDayOnes,
    getCityDiscounts,
    getOpdCovers,
    getCoPayments,
    getCorporateFloaters,
    getDiseaseSubLimits,
    getFloaterDiscs,
    getFormulas,
    getGoodClaimDiscounts,
    getGroupDiscs,
    getInsurers,
    getMaternities,
    getMaternityDiscounts,
    getNewInsertions,
    getParentalCoPayments,
    getPedLoadings,
    getPrePostNatals,
    getPremiums,
    getRncClaimIntimationNetworks,
    getRoomRentCapings,
    getUsers,

    // add apis 
    addAdverseClaimLoadings,
    addAgeBandDicounts,
    addBabyDayOnes,
    addCityDiscounts,
    addCoPayments,
    addCorporateFloaters,
    addDiseaseSubLimits,
    addFloaterDiscs,
    addFormulas,
    addGoodClaimDiscounts,
    addGroupDiscs,
    addInsurers,
    addMaternities,
    addMaternityDiscounts,
    addNewInsertions,
    addParentalCopayments,
    addPedLoadings,
    addPrePostNatals,
    addPremiums,
    addRncClaimIntimationNetworks,
    addRoomRentCapings,
    addUser,
    addOpdCovers,

    // delete api's 
    deleteItem,

    // update Api's 
    updateAdverseClaimLoading,
    updateAgeBandDiscount,
    updateBabyDayOne,
    updateCityDiscount,
    updateCoPayment,
    updateCorporateFloater,
    updateDiseaseSubLimit,
    updateFloaterDisc,
    updateFormula,
    updateGoodClaimDiscount,
    updateGroupDisc,
    updateInsurer,
    updateMaternitie,
    updateMaternityDiscount,
    updateNewInsertion,
    updateParentalCopayment,
    updatePedLoading,
    updatePrePostNatal,
    updatePremium,
    updateRncClaimIntimationNetwork,
    updateRoomRentCaping,
    updateUser,
    updateOpdCover
};

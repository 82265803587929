/**
 * Fetch data from given url
 * @param {*} url
 * @param {*} options
 */
 const fetchJSON = (url, options = {}) => {
    return fetch(url, options)
        .then(response => {
            if (!response.status === 200) {
                throw response.json();
            }
            return response.json();
        })
        .then(json => {
            console.log('response' , json)
            return json;
            
        })
        .catch(error => {
            throw error;
        });
};

export { fetchJSON };
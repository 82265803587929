import React, { useState } from 'react';
import { Row, Col, Skeleton, Button, Popconfirm, Avatar, message } from 'antd';
import { Card } from 'reactstrap';
import { Link } from 'react-router-dom';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';

import Table from '../../components/Table';
import { deleteItem, getOpdCovers, getCount } from '../../helpers/apiCalls';
import appConfig from '../../config';

const columns = [
    {
        title: 'Id',
        dataIndex: 'index',
        key: 'index',
    },
    {
        title: 'Insurer',
        dataIndex: 'insurer',
        key: 'insurer',
    },
    {
        title: 'Created At',
        dataIndex: 'createdAt',
        key: 'createdAt',
    },
    {
        title: 'Updated At',
        dataIndex: 'updatedAt',
        key: 'updatedAt',
    },
    {
        title: '',
        dataIndex: 'action',
        key: 'action',
    },
];

const OpdCovers = () => {

    const [claimLoadings, setClaimLoadings] = useState([]);
    const [pagination, setPagination] = React.useState({
        current: 1,
        pageSize: 10,
    });
    const [search, setSearch] = useState({
        searchText: '',
        searchedColumn: '',
    });
    const [loading, setLoading] = React.useState(false);
    const [total, setTotal] = React.useState(10);

    const getCountFunc = () => {
        getCount('opd-covers', search.searchText, search.searchedColumn).then((res) => setTotal(res));
    }

    React.useEffect(() => {
        getOpdCover({ pagination });
        getCountFunc()
    }, []);


    const getOpdCover = (params) => {
        setLoading(true);
        const { limit, start } = getRandomuserParams(params);
        getOpdCovers('', limit, start, search.searchText, search.searchedColumn).then((res) => {
            console.log("result", res)
            //## getPRoductss
            const data = res.map((item, index) => ({
                index: index + 1,
                key: item.id,
                insurer: (item.insurer?.id) ? item.insurer.name : "N/A",
                createdAt: new Date(item.created_at).toDateString(),
                updatedAt: new Date(item.updated_at).toDateString(),
                action: (
                    <>
                        <Link className="text-success cursor-pointer me-3" to={{ pathname: `/edit/opdcovers/${item.id}` }}>
                            <EditOutlined />
                        </Link>
                        <Popconfirm
                            title="Are you sure?"
                            onConfirm={() => deleteOpdCover(item.id)}
                            onCancel={() => console.log('cancelled')}
                            okText="Yes"
                            cancelText="No">
                            <DeleteOutlined className="text-danger cursor-pointer" />
                        </Popconfirm>
                    </>
                )
            }));
            setClaimLoadings(data)
            setPagination({ ...params.pagination });
            setLoading(false);
        });
    };


    const deleteOpdCover = (id) => {
        deleteItem('opdCovers', id)
            .then((res) => {
                console.log('deleted', res)
                message.success('Deleted successfully');
                getCountFunc()
                getOpdCover({ pagination })
            })
            .catch((err) => message.error('Cannot be deleted'));
    };

    const searchCallback = (value) => {
        console.log(value, 'callback');
        setSearch(value);
    };
    const getRandomuserParams = (params) => ({
        limit: params.pagination.pageSize,
        start:
            params.pagination.current > 1
                ? params.pagination.current * params.pagination.pageSize - params.pagination.pageSize
                : 0,
        ...params,
    });
    const handleTableChange = (pagination, filters, sorter) => {
        getOpdCover({
            sortField: sorter.field,
            sortOrder: sorter.order,
            pagination,
            ...filters,
        });
    };
    console.log('loadings', claimLoadings)
    return (
        <Row className="my-5 ">
            <Card className="w-100 p-3">
                <div className="d-flex mb-4 px-3 align-items-center justify-content-between">
                    <div className="d-flex align-items-center">
                        <h1 className="me-3">OPD Covers</h1>
                        <span>{total} Entries Found</span>
                    </div>
                    <Link to="/add/opdcovers">
                        <button
                            className="rounded-pill outline-none  text-light"
                            type="submit"
                            style={{ background: '#a3af60', border: 'none', padding: '10px 15px' }}>
                            Add New
                        </button>
                    </Link>
                </div>

                <Col span={26} style={{ padding: '0px' }}>
                    {claimLoadings.length ? (
                        <Table
                            data={claimLoadings}
                            columns={columns}
                            url={`/edit/opdcovers/`}
                            searchCallback={searchCallback}
                            loading={loading}
                            handleTableChange={handleTableChange}
                            pagination={{ ...pagination, total, position: ['bottomCenter'] }}
                        />
                    ) : (
                        <Skeleton active />
                    )}
                </Col>
            </Card>
        </Row>
    );
};

export default OpdCovers;

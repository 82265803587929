import React, { useState } from 'react';
import { PlusOutlined, MinusOutlined, DeleteOutlined } from '@ant-design/icons';
import Label from 'reactstrap/lib/Label';
import { Form, Collapse, Row, Col, Switch, Select, Input, Button } from 'antd';
import appConfig from '../../config';
import { Checkbox } from 'antd';


const { Panel } = Collapse;
const { Option } = Select;
const { TextArea } = Input;


const FormList = ({ form: form, name: Name, title: title, funcN: funcN, calcData:calcData }) => {
    const [options, setOptions] = useState([]);
    const [selectedOption, setSelectedOption] = useState([])
    const [calcInputVisible, setCalcInputVisible] = useState(false)
    const [data, setData] = useState()
    // const [calcData, setCalcData] = useState(props.calcData)

    // setData(form.getFieldValue(Name))

    return (
        <Form.List
            name={Name}
            initialValue={[
                {
                    title: '',
                    description: '',
                    funcName: '',
                    input: '',
                    var1: '',
                    var2: '',
                    operator: '',
                },
            ]}>
            {(fields, { add, remove }) => (

                // console.log(fields),
                <>
                    {fields.map(({ key, name, fieldKey, ...restField }) => (
                        // console.log(key),
                        <Collapse
                            key={key}
                            ghost
                            defaultActiveKey={['0']}
                            expandIconPosition="right"
                            expandIcon={({ isActive }) =>
                                isActive ? (
                                    <MinusOutlined style={{ fontSize: '16px' }} />
                                ) : (
                                    <PlusOutlined style={{ fontSize: '16px' }} />
                                )
                            }>
                            <Panel className="mb-2" header={<h6>{`Entry ${key + 1}`}</h6>} key={key + 1}>
                                <Row
                                    key={key}
                                    style={{
                                        marginBottom: '10px',
                                        borderBottom: '1px solid #d3d3d3',
                                    }}>
                                    <Col span={22}>
                                        <Row
                                            key={key + 1}
                                        >
                                            <Col span={12}>
                                                <Label>Title</Label>
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'title']}
                                                    fieldKey={[fieldKey, 'title']}>
                                                    <Input placeholder="Title" />
                                                </Form.Item>
                                            </Col>

                                            <Col span={12}>
                                                <Label>Description</Label>
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'description']}
                                                    fieldKey={[fieldKey, 'description']}>
                                                    <TextArea rows={4} placeholder="Description" />
                                                </Form.Item>
                                            </Col>

                                            <Col span={24}>
                                                <Label>FuncName</Label>
                                                <Form.Item name={[name, 'funcName']}
                                                    wrapperCol={{ span: 22 }}>
                                                    <Select
                                                        onSelect={(a, b) => { setSelectedOption(a) }}
                                                        onClick={() => { setCalcInputVisible(false) }}
                                                        value={selectedOption}
                                                        placeholder="Function Name"
                                                        allowClear>
                                                        {appConfig.functionList.map((fun) => {
                                                            return (
                                                                <Option key={fun} name='funcName' value={fun}>
                                                                    {fun}
                                                                </Option>)
                                                        })}
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            {selectedOption === 'sumAll' && <Col span={24}>
                                            {console.log('calcdata', calcData)}
                                                <Label>Baisc Calc Input</Label>
                                                {selectedOption === 'sumAll' && calcData.map((e, index) => {
                                                    
                                                    return (<Checkbox value={e.retParam} name='basicCalcInput' key={index} onChange={this.checkboxHandler.bind(this)}>{e.retParam}</Checkbox>)
                                                })}
                                            </Col>}

                                            {selectedOption === "basicCalc" && <Col span={24}>
                                                <Label>basicCalcInput</Label>
                                                <Col span={6}>
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, 'var1']}
                                                        fieldKey={[fieldKey, 'var1']}>
                                                        <Input placeholder="var1" />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={6}>

                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, 'var2']}
                                                        fieldKey={[fieldKey, 'var2']}>
                                                        <Input placeholder="var1" />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={6}>

                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, 'operator']}
                                                        fieldKey={[fieldKey, 'operator']}>

                                                        <Select
                                                            placeholder="operator"
                                                            allowClear>

                                                            <Option key={"%"} name='operator' value={"%"}>
                                                                {'%'}
                                                            </Option>
                                                            <Option key={"+"} name='operator' value={"+"}>
                                                                {'+'}
                                                            </Option>
                                                            <Option key={"-"} name='operator' value={"-"}>
                                                                {'-'}
                                                            </Option>
                                                        </Select>
                                                    </Form.Item>
                                                </Col>
                                            </Col>}

                                            <Col span={12}>
                                                <Label>Input</Label>
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'input']}
                                                    fieldKey={[fieldKey, 'input']}>
                                                    <Input placeholder="Input" />
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                                <Label>RetParam</Label>
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'retParam']}
                                                    fieldKey={[fieldKey, 'retParam']}>
                                                    <Input placeholder="RetParam" />

                                                </Form.Item>
                                            </Col>


                                        </Row>
                                    </Col>

                                    <Col span={2}>
                                        <DeleteOutlined
                                            style={{ color: '#F43E3F' }}
                                            onClick={() => {
                                                remove(name);
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </Panel>
                        </Collapse>
                    ))}
                    <Row className="justify-content-center">
                        <Button
                            type="dashed"
                            onClick={() => add()}
                            style={{
                                width: '60%',
                                border: '1px dashed #a3af60',
                                color: '#a3af60',
                            }}
                            icon={<PlusOutlined />}>
                            Add Entry
                        </Button>
                    </Row>
                </>
            )}
        </Form.List>
    );
}
export default FormList;

import React from 'react';
import { Redirect } from 'react-router-dom';
import { Route } from 'react-router-dom';
import * as FeatherIcon from 'react-feather';
import NoAccess from '../pages/auth/NoAccess';

import { isUserAuthenticated, getLoggedInUser } from '../helpers/authUtils';
import OpdCovers from '../pages/pages/OpdCovers';
import AddOpdCover from '../pages/upload-pages/AddOpdCover';
import EditOpdCovers from '../pages/edit-pages/EditOpdCover';

// auth
const Login = React.lazy(() => import('../pages/auth/Login'));
const Logout = React.lazy(() => import('../pages/auth/Logout'));
const Confirm = React.lazy(() => import('../pages/auth/Confirm'));

// userpages 
const MyAccount = React.lazy(() => import('../pages/user/myaccount'));
// dashboard
const Dashboard = React.lazy(() => import('../pages/dashboard'));
// apps
const CalendarApp = React.lazy(() => import('../pages/apps/Calendar'));
const EmailInbox = React.lazy(() => import('../pages/apps/Email/Inbox'));
const EmailDetail = React.lazy(() => import('../pages/apps/Email/Detail'));
const EmailCompose = React.lazy(() => import('../pages/apps/Email/Compose'));
const ProjectList = React.lazy(() => import('../pages/apps/Project/List'));
const ProjectDetail = React.lazy(() => import('../pages/apps/Project/Detail/'));
const TaskList = React.lazy(() => import('../pages/apps/Tasks/List'));
const TaskBoard = React.lazy(() => import('../pages/apps/Tasks/Board'));

// pages
const AdverseClaimLoadings = React.lazy(() => import('../pages/pages/AdverseClaimLoadings'));
const AgeBandDiscounts = React.lazy(() => import('../pages/pages/AgeBandDiscounts'));
const BabyDayOnes = React.lazy(() => import('../pages/pages/BabyDayOnes'));
const CityDiscounts = React.lazy(() => import('../pages/pages/CityDiscounts'));
const CoPayments = React.lazy(() => import('../pages/pages/CoPayments'));
const CorporateFloaters = React.lazy(() => import('../pages/pages/CorporateFloaters'));
const DiseaseSubLimits = React.lazy(() => import('../pages/pages/DiseaseSubLimits'));
const FloaterDiscs = React.lazy(() => import('../pages/pages/FloaterDiscs'));
const Formulas = React.lazy(() => import('../pages/pages/Formulas'));
const GoodClaimDiscounts = React.lazy(() => import('../pages/pages/GoodClaimDiscounts'));
const GroupDiscs = React.lazy(() => import('../pages/pages/GroupDiscs'));
const Insurers = React.lazy(() => import('../pages/pages/Insurers'));
const Maternities = React.lazy(() => import('../pages/pages/Maternities'));
const MaternityDiscounts = React.lazy(() => import('../pages/pages/MaternityDiscounts'));
const NewInsertions = React.lazy(() => import('../pages/pages/NewInsertions'));
const ParentalCopayments = React.lazy(() => import('../pages/pages/ParentalCopayments'));
const PedLoadings = React.lazy(() => import('../pages/pages/PedLoadings'));
const Premiums = React.lazy(() => import('../pages/pages/Premiums'));
const PrePostNatals = React.lazy(() => import('../pages/pages/PrePostNatals'));
const RncClaimIntimationNetworks = React.lazy(() => import('../pages/pages/RncClaimIntimationNetworks'));
const RoomRentCapings = React.lazy(() => import('../pages/pages/RoomRentCapings'));
const Users = React.lazy(() => import('../pages/pages/User'));


//  add pages 
const AddAdverseClaimLoadings = React.lazy(() => import('../pages/upload-pages/AddAdverseClaimLoadings'))
const AddAgeBandDiscounts = React.lazy(() => import('../pages/upload-pages/AddAgeBandDiscounts'))
const AddBabyDayOnes = React.lazy(() => import('../pages/upload-pages/AddBabyDayOnes'));
const AddCityDiscounts = React.lazy(() => import('../pages/upload-pages/AddCityDiscounts'))
const AddCoPayments = React.lazy(() => import('../pages/upload-pages/AddCoPayments'))
const AddCorporateFloaters = React.lazy(() => import('../pages/upload-pages/AddCorporateFloaters'))
const AddDiseaseSubLimits = React.lazy(() => import('../pages/upload-pages/AddDiseaseSubLimits'));
const AddFloaterDiscs = React.lazy(() => import('../pages/upload-pages/AddFloaterDiscs'));
const AddFormulas = React.lazy(() => import('../pages/upload-pages/AddFormulas'));
const AddGoodClaimDiscounts = React.lazy(() => import('../pages/upload-pages/AddGoodClaimDiscounts'));
const AddGroupDiscs = React.lazy(() => import('../pages/upload-pages/AddGroupDiscs'));
const AddInsurers = React.lazy(() => import('../pages/upload-pages/AddInsurers'));
const AddMaternities = React.lazy(() => import('../pages/upload-pages/AddMaternities'));
const AddMaternityDiscounts = React.lazy(() => import('../pages/upload-pages/AddMaternityDiscounts'));
const AddNewInsertions = React.lazy(() => import('../pages/upload-pages/AddNewInsertions'));
const AddParentalCopayments = React.lazy(() => import('../pages/upload-pages/AddParentalCopayments'));
const AddPedLoadings = React.lazy(() => import('../pages/upload-pages/AddPedLoadings'));
const AddPrePostNatals = React.lazy(() => import('../pages/upload-pages/AddPrePostNatals'));
const AddPremiums = React.lazy(() => import('../pages/upload-pages/AddPremiums'));
const AddRncClaimIntimationNetworks = React.lazy(() => import('../pages/upload-pages/AddRncClaimIntimationNetworks'));
const AddRoomRentCapings = React.lazy(() => import('../pages/upload-pages/AddRoomRentCapings'));
const AddUser = React.lazy(() => import('../pages/upload-pages/AddUser'))

// Edit Pages 
const EditAdverseClaimLoadings = React.lazy(() => import('../pages/edit-pages/EditAdverseClaimLoadings'));
const EditAgeBandDiscounts = React.lazy(() => import('../pages/edit-pages/EditAgeBandDiscounts'));
const EditBabyDayOnes = React.lazy(() => import('../pages/edit-pages/EditBabyDayOnes'));
const EditCityDiscounts = React.lazy(() => import('../pages/edit-pages/EditCityDiscounts'));
const EditCoPayments = React.lazy(() => import('../pages/edit-pages/EditCoPayments'));
const EditCorporateFloaters = React.lazy(() => import('../pages/edit-pages/EditCorporateFloaters'));
const EditDiseaseSubLimits = React.lazy(() => import('../pages/edit-pages/EditDiseaseSubLimits'));
const EditFloaterDiscs = React.lazy(() => import('../pages/edit-pages/EditFloaterDiscs'));
const EditFormulas = React.lazy(() => import('../pages/edit-pages/EditFormulas'));
const EditGoodClaimDiscounts = React.lazy(() => import('../pages/edit-pages/EditGoodClaimDiscounts'));
const EditGroupDiscs = React.lazy(() => import('../pages/edit-pages/EditGroupDiscs'));
const EditInsurers = React.lazy(() => import('../pages/edit-pages/EditInsurers'));
const EditMaternities = React.lazy(() => import('../pages/edit-pages/EditMaternities'));
const EditMaternityDiscounts = React.lazy(() => import('../pages/edit-pages/EditMaternityDiscounts'));
const EditNewInsertions = React.lazy(() => import('../pages/edit-pages/EditNewInsertions'));
const EditParentalCopayments = React.lazy(() => import('../pages/edit-pages/EditParentalCopayments'));
const EditPedLoadings = React.lazy(() => import('../pages/edit-pages/EditPedLoadings'));
const EditPrePostNatals = React.lazy(() => import('../pages/edit-pages/EditPrePostNatals'));
const EditPremiums = React.lazy(() => import('../pages/edit-pages/EditPremiums'));
const EditRncClaimIntimationNetworks = React.lazy(() => import('../pages/edit-pages/EditRncClaimIntimationNetworks'));
const EditRoomRentCapings = React.lazy(() => import('../pages/edit-pages/EditRoomRentCapings'));
const EditUserB = React.lazy(() => import('../pages/edit-pages/EditUserBackup'))

const Starter = React.lazy(() => import('../pages/other/Starter'));
const Profile = React.lazy(() => import('../pages/other/Profile/'));
const Activity = React.lazy(() => import('../pages/other/Activity'));
const Invoice = React.lazy(() => import('../pages/other/Invoice'));
const Pricing = React.lazy(() => import('../pages/other/Pricing'));
const Error404 = React.lazy(() => import('../pages/other/Error404'));
const Error500 = React.lazy(() => import('../pages/other/Error500'));

// ui
const BSComponents = React.lazy(() => import('../pages/uikit/BSComponents/'));
const FeatherIcons = React.lazy(() => import('../pages/uikit/Icons/Feather'));
const UniconsIcons = React.lazy(() => import('../pages/uikit/Icons/Unicons'));
const Widgets = React.lazy(() => import('../pages/uikit/Widgets/'));

// charts
const Charts = React.lazy(() => import('../pages/charts/'));

// forms
const BasicForms = React.lazy(() => import('../pages/forms/Basic'));
const FormAdvanced = React.lazy(() => import('../pages/forms/Advanced'));
const FormValidation = React.lazy(() => import('../pages/forms/Validation'));
const FormWizard = React.lazy(() => import('../pages/forms/Wizard'));
const FileUpload = React.lazy(() => import('../pages/forms/FileUpload'));
const Editor = React.lazy(() => import('../pages/forms/Editor'));

// tables
const BasicTables = React.lazy(() => import('../pages/tables/Basic'));
const AdvancedTables = React.lazy(() => import('../pages/tables/Advanced'));

// User Pages
const Home = React.lazy(() => import('../pages/user/home'));

// handle auth and authorization
const PrivateRoute = ({ component: Component, roles, ...rest }) => (
    <Route
        {...rest}
        render={(props) => {
            if (!isUserAuthenticated()) {
                // not logged in so redirect to login page with the return url
                return <Redirect to={{ pathname: '/account/login', state: { from: props.location } }} />;
            }

            const loggedInUser = getLoggedInUser();

            // check if route is restricted by role
            console.log('LoggedIN USER ');
            if (roles && roles.indexOf(loggedInUser.user.type) === -1) {
                // role not authorised so redirect to home page
                return <Redirect to={{ pathname: '/no-access' }} />;
            }

            // authorised so return component
            return <Component {...props} />;
        }}
    />
);

// root routes
const rootRoute = {
    path: '/',
    exact: true,
    component: () => <Redirect to="/home" />,
    route: PrivateRoute,
};

// User
// adverse claim loadings
const AdverseCLoadings = {
    path: '/adverseclaimloadings',
    name: 'Adverse Claim Loadings',
    icon: FeatherIcon.Home,
    component: AdverseClaimLoadings,
    roles: ['HQ', 'chef'],
    route: PrivateRoute,
};

// age band discounts
const AgeBDiscounts = {
    path: '/agebanddiscounts',
    name: 'Age Band Discounts',
    icon: FeatherIcon.Home,
    component: AgeBandDiscounts,
    roles: ['HQ', 'chef'],
    route: PrivateRoute,
};

// BabyDayOnes
const BabyDOnes = {
    path: '/babydayones',
    name: 'Baby Day Ones',
    icon: FeatherIcon.Home,
    component: BabyDayOnes,
    roles: ['HQ', 'chef'],
    route: PrivateRoute,
}


// CityDiscounts
const CityDisc = {
    path: '/citydiscounts',
    name: 'City Discounts',
    icon: FeatherIcon.Home,
    component: CityDiscounts,
    roles: ['HQ', 'chef'],
    route: PrivateRoute,
}

// CoPayments
const CoPaym = {
    path: '/copayments',
    name: 'Co Payments',
    icon: FeatherIcon.Home,
    component: CoPayments,
    roles: ['HQ', 'chef'],
    route: PrivateRoute,
}

// CorporateFloaters
const CorporateFloater = {
    path: '/corporatefloaters',
    name: 'Corporate Floaters',
    icon: FeatherIcon.Home,
    component: CorporateFloaters,
    roles: ['HQ', 'chef'],
    route: PrivateRoute,
}

// DiseaseSubLimits
const DiseaseSLimits = {
    path: '/diseasesublimits',
    name: 'Disease Sublimits',
    icon: FeatherIcon.Home,
    component: DiseaseSubLimits,
    roles: ['HQ', 'chef'],
    route: PrivateRoute,
}

// opd covers
const OpdCover = {
    path: '/opdcovers',
    name: 'OPD Covers',
    icon: FeatherIcon.Home,
    component: OpdCovers,
    roles: ['HQ', 'chef'],
    route: PrivateRoute,
}


// FloaterDiscs
const FloaterDisc = {
    path: '/floaterdiscs',
    name: 'Floater Discs',
    icon: FeatherIcon.Home,
    component: FloaterDiscs,
    roles: ['HQ', 'chef'],
    route: PrivateRoute,
}

// Formulas 
const Formul = {
    path: '/formulas',
    name: 'Formulas',
    icon: FeatherIcon.Home,
    component: Formulas,
    roles: ['HQ', 'chef'],
    route: PrivateRoute,
}

// GoodClaimDiscounts
const GoodCDiscounts = {
    path: '/goodclaimdiscounts',
    name: 'Good Claim Discounts',
    icon: FeatherIcon.Home,
    component: GoodClaimDiscounts,
    roles: ['HQ', 'chef'],
    route: PrivateRoute,
}

// GroupDiscs
const GroupDisc = {
    path: '/groupdiscs',
    name: 'Group Discs',
    icon: FeatherIcon.Home,
    component: GroupDiscs,
    roles: ['HQ', 'chef'],
    route: PrivateRoute,
}
// Insurers
const Insurer = {
    path: '/insurers',
    name: 'Insurers',
    icon: FeatherIcon.Home,
    component: Insurers,
    roles: ['HQ', 'chef'],
    route: PrivateRoute,
}

// Maternities
const Maternitie = {
    path: '/maternities',
    name: 'Maternities',
    icon: FeatherIcon.Home,
    component: Maternities,
    roles: ['HQ', 'chef'],
    route: PrivateRoute,
}

// MaternityDiscounts
const MaternityDiscount = {
    path: '/maternitydiscounts',
    name: 'Maternity Discounts',
    icon: FeatherIcon.Home,
    component: MaternityDiscounts,
    roles: ['HQ', 'chef'],
    route: PrivateRoute,
}

// NewInsertions
const NewInsertion = {
    path: '/newinsertions',
    name: 'NewInsertions',
    icon: FeatherIcon.Home,
    component: NewInsertions,
    roles: ['HQ', 'chef'],
    route: PrivateRoute,
}

// ParentalCopayments
const ParentalCopayment = {
    path: '/parentalcopayments',
    name: 'ParentalCopayments',
    icon: FeatherIcon.Home,
    component: ParentalCopayments,
    roles: ['HQ', 'chef'],
    route: PrivateRoute,
}

// PedLoadings
const PedLoading = {
    path: '/pedloadings',
    name: 'PedLoadings',
    icon: FeatherIcon.Home,
    component: PedLoadings,
    roles: ['HQ', 'chef'],
    route: PrivateRoute,
}

// PrePostNatals
const PrePostNatal = {
    path: '/prepostnatals',
    name: 'PrePostNatals',
    icon: FeatherIcon.Home,
    component: PrePostNatals,
    roles: ['HQ', 'chef'],
    route: PrivateRoute,
}

// Premiums
const Premium = {
    path: '/premiums',
    name: 'Premiums',
    icon: FeatherIcon.Home,
    component: Premiums,
    roles: ['HQ', 'chef'],
    route: PrivateRoute,
}

// RncClaimIntimationNetworks
const RncClaimIntimationNetwork = {
    path: '/rncclaimintimationnetworks',
    name: 'RncClaimIntimationNetworks',
    icon: FeatherIcon.Home,
    component: RncClaimIntimationNetworks,
    roles: ['HQ', 'chef'],
    route: PrivateRoute,
}



// RoomRentCapings
const RoomRentCaping = {
    path: '/roomrentcapings',
    name: 'Room Rent Capings',
    icon: FeatherIcon.Home,
    component: RoomRentCapings,
    roles: ['HQ', 'chef'],
    route: PrivateRoute,
}

// Users
const User = {
    path: '/users',
    name: 'Users',
    icon: FeatherIcon.Home,
    component: Users,
    roles: ['HQ', 'chef'],
    route: PrivateRoute,
}
const User2 = {
    path: '/users',
    name: 'Users',
    icon: FeatherIcon.Home,
    component: Users,
    roles: ['HQ', 'chef'],
    route: PrivateRoute,
}

const homeRoutes = {
    path: '/home',
    name: 'Home',
    icon: FeatherIcon.Home,
    component: Home,
    roles: ['HQ', 'chef'],
    route: PrivateRoute,
};






//branch


//addRoutes
const myAccountRoutes = {
    path: '/my-account',
    name: 'My Account',
    icon: FeatherIcon.User,
    // header: 'Navigation',
    component: MyAccount,
    roles: ['superAdmin', '"HQ"'],
    route: PrivateRoute,
};
const addRoutes = {
    path: '/add',
    name: 'add',
    children: [
        {
            path: '/add/adverseclaimloadings',
            name: 'Add AdverseClaimLoadings',
            component: AddAdverseClaimLoadings,
            roles: ['HQ', 'chef'],

            route: Route,
        },
        {
            path: '/add/opdcovers',
            name: 'Add OPD Cover',
            component: AddOpdCover,
            roles: ['HQ', 'chef'],

            route: Route,
        },
        {
            path: '/add/agebanddiscounts',
            name: 'Add AgeBandDiscounts',
            component: AddAgeBandDiscounts,
            roles: ['HQ', 'chef'],

            route: Route,
        },
        {
            path: '/add/babydayones',
            name: 'Add BabyDayOnes',
            component: AddBabyDayOnes,
            roles: ['HQ', 'chef'],

            route: Route,
        },
        {
            path: '/add/citydiscounts',
            name: 'Add CityDiscounts',
            component: AddCityDiscounts,
            roles: ['HQ', 'chef'],

            route: Route,
        },
        {
            path: '/add/copayments',
            name: 'Add CoPayments',
            component: AddCoPayments,
            roles: ['HQ', 'chef'],

            route: Route,
        },
        {
            path: '/add/corporatefloaters',
            name: 'Add CorporateFloaters',
            component: AddCorporateFloaters,
            roles: ['HQ', 'chef'],

            route: Route,
        },
        {
            path: '/add/diseasesublimits',
            name: 'Add DiseaseSublimits',
            component: AddDiseaseSubLimits,
            roles: ['HQ', 'chef'],

            route: Route,
        },
        {
            path: '/add/floaterdiscs',
            name: 'Add FloaterDiscs',
            component: AddFloaterDiscs,
            roles: ['HQ', 'chef'],

            route: Route,
        },
        {
            path: '/add/formulas',
            name: 'Add Formulas',
            component: AddFormulas,
            roles: ['HQ', 'chef'],

            route: Route,
        },
        {
            path: '/add/goodclaimdiscounts',
            name: 'Add GoodClaimDiscounts',
            component: AddGoodClaimDiscounts,
            roles: ['HQ', 'chef'],

            route: Route,
        },
        {
            path: '/add/groupdiscs',
            name: 'Add GroupDiscs',
            component: AddGroupDiscs,
            roles: ['HQ', 'chef'],

            route: Route,
        },
        {
            path: '/add/insurers',
            name: 'Add Insurers',
            component: AddInsurers,
            roles: ['HQ', 'chef'],

            route: Route,
        },

        {
            path: '/add/maternities',
            name: 'Add Maternities',
            component: AddMaternities,
            roles: ['HQ', 'chef'],

            route: Route,
        },

        {
            path: '/add/maternitydiscounts',
            name: 'Add MaternityDiscounts',
            component: AddMaternityDiscounts,
            roles: ['HQ', 'chef'],

            route: Route,
        },

        {
            path: '/add/newinsertions',
            name: 'Add NewInsertions',
            component: AddNewInsertions,
            roles: ['HQ', 'chef'],

            route: Route,
        },

        {
            path: '/add/parentalcopayments',
            name: 'Add ParentalCopayments',
            component: AddParentalCopayments,
            roles: ['HQ', 'chef'],

            route: Route,
        },
        {
            path: '/add/pedloadings',
            name: 'Add PedLoadings',
            component: AddPedLoadings,
            roles: ['HQ', 'chef'],

            route: Route,
        },

        {
            path: '/add/prepostnatals',
            name: 'Add PrePostNatals',
            component: AddPrePostNatals,
            roles: ['HQ', 'chef'],

            route: Route,
        },
        {
            path: '/add/premiums',
            name: 'Add Premiums',
            component: AddPremiums,
            roles: ['HQ', 'chef'],

            route: Route,
        },
        {
            path: '/add/rncclaimintimationnetworks',
            name: 'Add RncClaimIntimationNetwork',
            component: AddRncClaimIntimationNetworks,
            roles: ['HQ', 'chef'],

            route: Route,
        },
        {
            path: '/add/roomrentcapings',
            name: 'Add RoomRentCapings',
            component: AddRoomRentCapings,
            roles: ['HQ', 'chef'],

            route: Route,
        },
        {
            path: '/add/user',
            name: "Add User",
            component: AddUser,
            roles: ['HQ', 'chef'],
            route: Route,
        }
    ],
};

//editRoutes

const editRoutes = {
    path: '/edit',
    name: 'edit',
    children: [
        {
            path: '/edit/adverseclaimloadings/:id',
            name: 'AdverseClaimLoading Edit',
            roles: ['HQ', 'chef'],

            component: EditAdverseClaimLoadings,
            route: Route,
        },
        {
            path: '/edit/agebanddiscounts/:id',
            name: 'AgeBandDiscount Edit',
            roles: ['HQ', 'chef'],

            component: EditAgeBandDiscounts,
            route: Route,
        },
        {
            path: '/edit/opdcovers/:id',
            name: 'Opd Cover Edit',
            roles: ['HQ', 'chef'],

            component: EditOpdCovers,
            route: Route,
        },
        {
            path: '/edit/babydayones/:id',
            name: 'BabyDayOnes Edit',
            roles: ['HQ', 'chef'],

            component: EditBabyDayOnes,
            route: Route,
        },
        {
            path: '/edit/adverseclaimloadings/:id',
            name: 'AdverseClaimLoading Edit',
            roles: ['HQ', 'chef'],

            component: EditAdverseClaimLoadings,
            route: Route,
        },
        {
            path: '/edit/citydiscounts/:id',
            name: 'CityDiscounts Edit',
            roles: ['HQ', 'chef'],

            component: EditCityDiscounts,
            route: Route,
        },
        {
            path: '/edit/copayments/:id',
            name: 'CoPayments Edit',
            roles: ['HQ', 'chef'],

            component: EditCoPayments,
            route: Route,
        },
        {
            path: '/edit/corporatefloaters/:id',
            name: 'Corporatefloaters Edit',
            roles: ['HQ', 'chef'],

            component: EditCorporateFloaters,
            route: Route,
        },
        {
            path: '/edit/diseasesublimits/:id',
            name: 'DiseaseSubLimits Edit',
            roles: ['HQ', 'chef'],

            component: EditDiseaseSubLimits,
            route: Route,
        },
        {
            path: '/edit/floaterdiscs/:id',
            name: 'FloaterDiscs Edit',
            roles: ['HQ', 'chef'],

            component: EditFloaterDiscs,
            route: Route,
        },
        {
            path: '/edit/formulas/:id',
            name: 'Formulas Edit',
            roles: ['HQ', 'chef'],

            component: EditFormulas,
            route: Route,
        },
        {
            path: '/edit/goodclaimdiscounts/:id',
            name: 'GoodClaimDiscounts Edit',
            roles: ['HQ', 'chef'],

            component: EditGoodClaimDiscounts,
            route: Route,
        },
        {
            path: '/edit/groupdiscs/:id',
            name: 'GroupDiscs Edit',
            roles: ['HQ', 'chef'],

            component: EditGroupDiscs,
            route: Route,
        },
        {
            path: '/edit/insurers/:id',
            name: 'Insurers Edit',
            roles: ['HQ', 'chef'],

            component: EditInsurers,
            route: Route,
        },
        {
            path: '/edit/maternities/:id',
            name: 'Maternities Edit',
            roles: ['HQ', 'chef'],

            component: EditMaternities,
            route: Route,
        },
        {
            path: '/edit/maternitydiscounts/:id',
            name: 'MaternityDiscounts Edit',
            roles: ['HQ', 'chef'],

            component: EditMaternityDiscounts,
            route: Route,
        },
        {
            path: '/edit/newinsertions/:id',
            name: 'NewInsertions Edit',
            roles: ['HQ', 'chef'],

            component: EditNewInsertions,
            route: Route,
        },
        {
            path: '/edit/parentalcopayments/:id',
            name: 'ParentalCopayments Edit',
            roles: ['HQ', 'chef'],

            component: EditParentalCopayments,
            route: Route,
        },
        {
            path: '/edit/pedloadings/:id',
            name: 'PedLoadings Edit',
            roles: ['HQ', 'chef'],

            component: EditPedLoadings,
            route: Route,
        },
        {
            path: '/edit/prepostnatals/:id',
            name: 'PrePostNatals Edit',
            roles: ['HQ', 'chef'],

            component: EditPrePostNatals,
            route: Route,
        },
        {
            path: '/edit/premiums/:id',
            name: 'Premiums Edit',
            roles: ['HQ', 'chef'],

            component: EditPremiums,
            route: Route,
        },
        {
            path: '/edit/rncclaimintimationnetworks/:id',
            name: 'RncClaimIntimationNetworks Edit',
            roles: ['HQ', 'chef'],

            component: EditRncClaimIntimationNetworks,
            route: Route,
        },
        {
            path: '/edit/roomrentcapings/:id',
            name: 'RoomRentCapings Edit',
            roles: ['HQ', 'chef'],

            component: EditRoomRentCapings,
            route: Route,
        },
        {
            path: '/edit/user/:id',
            name: 'User Edit',
            roles: ['HQ', 'chef'],

            component: EditUserB,
            route: Route,
        }

    ],
};

// dashboards
const dashboardRoutes = {
    path: '/dashboard',
    name: 'Dashboard',
    icon: FeatherIcon.Home,
    header: 'Navigation',
    badge: {
        variant: 'success',
        text: '1',
    },
    component: Dashboard,
    roles: ['admin'],
    route: PrivateRoute,
};

// apps

const calendarAppRoutes = {
    path: '/apps/calendar',
    name: 'Calendar',
    header: 'Apps',
    icon: FeatherIcon.Calendar,
    component: CalendarApp,
    route: PrivateRoute,
    roles: ['Admin'],
};

const emailAppRoutes = {
    path: '/apps/email',
    name: 'Email',
    icon: FeatherIcon.Inbox,
    children: [
        {
            path: '/apps/email/inbox',
            name: 'Inbox',
            component: EmailInbox,
            route: PrivateRoute,
            roles: ['Admin'],
        },
        {
            path: '/apps/email/details',
            name: 'Details',
            component: EmailDetail,
            route: PrivateRoute,
            roles: ['Admin'],
        },
        {
            path: '/apps/email/compose',
            name: 'Compose',
            component: EmailCompose,
            route: PrivateRoute,
            roles: ['Admin'],
        },
    ],
};

const projectAppRoutes = {
    path: '/apps/projects',
    name: 'Projects',
    icon: FeatherIcon.Briefcase,
    children: [
        {
            path: '/apps/projects/list',
            name: 'List',
            component: ProjectList,
            route: PrivateRoute,
            roles: ['Admin'],
        },
        {
            path: '/apps/projects/detail',
            name: 'Detail',
            component: ProjectDetail,
            route: PrivateRoute,
            roles: ['Admin'],
        },
    ],
};

const taskAppRoutes = {
    path: '/apps/tasks',
    name: 'Tasks',
    icon: FeatherIcon.Bookmark,
    children: [
        {
            path: '/apps/tasks/list',
            name: 'List',
            component: TaskList,
            route: PrivateRoute,
            roles: ['Admin'],
        },
        {
            path: '/apps/tasks/board',
            name: 'Board',
            component: TaskBoard,
            route: PrivateRoute,
            roles: ['Admin'],
        },
    ],
};

// const appRoutes = [homeRoutes, logsRoutes, meetingsRoutes, webinarRoutes, myAccountRoutes];
const appRoutes = [
    homeRoutes,
    Formul,
    AdverseCLoadings,
    AgeBDiscounts,
    BabyDOnes,
    OpdCover,
    CityDisc,
    CoPaym,
    CorporateFloater,
    DiseaseSLimits,
    FloaterDisc,
    GoodCDiscounts,
    GroupDisc,
    Insurer,
    Maternitie,
    MaternityDiscount,
    NewInsertion,
    ParentalCopayment,
    PedLoading,
    PrePostNatal,
    Premium,
    RncClaimIntimationNetwork,
    RoomRentCaping,
    User,
    User2

];
// calendarAppRoutes, emailAppRoutes, projectAppRoutes, taskAppRoutes];

// pages
const pagesRoutes = {
    path: '/pages',
    name: 'Pages',

    children: [
        {
            path: '/pages/error-404',
            name: 'Error 404',
            component: Error404,
            route: Route,
        },
        {
            path: '/pages/error-500',
            name: 'Error 500',
            component: Error500,
            route: Route,
        },
    ],
};

// components
const componentsRoutes = {
    path: '/ui',
    name: 'UI Elements',
    header: 'Components',
    icon: FeatherIcon.Package,
    children: [
        {
            path: '/ui/bscomponents',
            name: 'Bootstrap UI',
            component: BSComponents,
            route: PrivateRoute,
            roles: ['Admin'],
        },
        {
            path: '/ui/icons',
            name: 'Icons',
            children: [
                {
                    path: '/ui/icons/feather',
                    name: 'Feather Icons',
                    component: FeatherIcons,
                    route: PrivateRoute,
                    roles: ['Admin'],
                },
                {
                    path: '/ui/icons/unicons',
                    name: 'Unicons Icons',
                    component: UniconsIcons,
                    route: PrivateRoute,
                    roles: ['Admin'],
                },
            ],
        },
        {
            path: '/ui/widgets',
            name: 'Widgets',
            component: Widgets,
            route: PrivateRoute,
            roles: ['Admin'],
        },
    ],
};

// charts
const chartRoutes = {
    path: '/charts',
    name: 'Charts',
    component: Charts,
    icon: FeatherIcon.PieChart,
    roles: ['Admin'],
    route: PrivateRoute,
};

// forms
const formsRoutes = {
    path: '/forms',
    name: 'Forms',
    icon: FeatherIcon.FileText,
    children: [
        {
            path: '/forms/basic',
            name: 'Basic Elements',
            component: BasicForms,
            route: PrivateRoute,
        },
        {
            path: '/forms/advanced',
            name: 'Advanced',
            component: FormAdvanced,
            route: PrivateRoute,
        },
        {
            path: '/forms/validation',
            name: 'Validation',
            component: FormValidation,
            route: PrivateRoute,
        },
        {
            path: '/forms/wizard',
            name: 'Wizard',
            component: FormWizard,
            route: PrivateRoute,
        },
        {
            path: '/forms/editor',
            name: 'Editor',
            component: Editor,
            route: PrivateRoute,
        },
        {
            path: '/forms/upload',
            name: 'File Upload',
            component: FileUpload,
            route: PrivateRoute,
        },
    ],
};

const tableRoutes = {
    path: '/tables',
    name: 'Tables',
    icon: FeatherIcon.Grid,
    children: [
        {
            path: '/tables/basic',
            name: 'Basic',
            component: BasicTables,
            route: PrivateRoute,
        },
        {
            path: '/tables/advanced',
            name: 'Advanced',
            component: AdvancedTables,
            route: PrivateRoute,
        },
    ],
};

// auth
const authRoutes = {
    path: '/account',
    name: 'Auth',
    children: [
        {
            path: '/account/login',
            name: 'Login',
            component: Login,
            route: Route,
        },
        {
            path: '/account/logout',
            name: 'Logout',
            component: Logout,
            route: Route,
        },

        {
            path: '/account/confirm',
            name: 'Confirm',
            component: Confirm,
            route: Route,
        },
        {
            path: '/no-access',
            name: 'No Access',
            component: NoAccess,
            route: Route,
        },

        // {
        //     path: '/webinarroom',
        //     name: 'WebinarRoom',
        //     component: WebinarRoom,
        //     route: Route,
        // },
        // {
        //     path: '/webinarreg/:cid',
        //     name: 'WebinarRegistration',
        //     component: WebinarRegistration,
        //     route: Route,
        // },
    ],
};

// flatten the list of all nested routes
const flattenRoutes = (routes) => {
    let flatRoutes = [];

    routes = routes || [];
    routes.forEach((item) => {
        flatRoutes.push(item);

        if (typeof item.children !== 'undefined') {
            flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
        }
    });
    return flatRoutes;
};

// All routes
const allRoutes = [
    rootRoute,
    // dashboardRoutes,
    ...appRoutes,
    pagesRoutes,
    addRoutes,
    editRoutes,
    // componentsRoutes,
    // chartRoutes,
    // formsRoutes,
    // tableRoutes,
    authRoutes,
];

// const authProtectedRoutes = [dashboardRoutes, ...appRoutes, pagesRoutes, componentsRoutes, chartRoutes, formsRoutes, tableRoutes];
const authProtectedRoutes = [...appRoutes];
const allFlattenRoutes = flattenRoutes(allRoutes);
export { allRoutes, authProtectedRoutes, allFlattenRoutes };